import React, { useState, useEffect } from "react"
import Styles from "../styles/team_profile.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import UserListItem from "../components/UserListItem";
import { BACK_BUTTON_STR } from "../constant/const";
import { UserType } from "../types/models/User";
import client from "../apis/client";

const LikeUserListPage = ( {
    location
}: any ) => {
    let post = location.state ?
        location.state.post : null

    const [ userList, setUserList ] = useState<UserType[]>( [] )
    const [ noUser, setNoUser ] = useState<boolean | null>( null )

    useEffect( () => {
        componentDidMount()
        document.documentElement.scrollTo( 0, 0 )
    }, [] )

    const headerOption = {
        headerTitle: "いいね！したユーザー",
        leftIcon: BACK_BUTTON_STR,
    }

    const componentDidMount = async () => {
        await client.get( `/api/post/${ post.pk }/like_user_list/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then( res => {
                setUserList( res.data.userList )
                res.data.userList.length === 0 && setNoUser( true )
            } )
            .catch( error => console.warn( error ) )
    }

    return (
        <Layout headerOption={ headerOption }>
            <div style={ { marginTop: 16, padding: 8 } }>
                <SEO title="LikeUser" />
                <div className={ Styles.container }>
                    { noUser &&
                        <p style={ { fontWeight: "bold", fontSize: 18, textAlign: "center", width: "100%" } }>
                            まだいいね！したユーザーはいません
                        </p> }
                    { userList.map( user =>
                        <UserListItem user={ user } />
                    ) }
                </div>
            </div>
        </Layout>
    )
}

export default LikeUserListPage
